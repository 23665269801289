import { fileSave } from "browser-fs-access";
import {
  DefaultButton,
  Dropdown,
  IStackTokens,
  Modal,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { TextField } from "@fluentui/react/lib/TextField";
import * as React from "react";
import * as ApiV2 from "../../ApiV2";
import { ModalContent } from "../../assets/style/modal/modal";
import ModalHeader from "../../control/modalHeader";
import { useLang } from "../../i18n/useLang";
import saveAs from "file-saver";
import { use } from "i18next";
import { LocalStorageKey } from "../../config/constant";

const TocApi = new ApiV2.TocApi();
const stackTokens: IStackTokens = { childrenGap: 10 };
const verticalStackTokens: IStackTokens = { childrenGap: 5 };

interface ExportTOCDocModalProps {
  isShow: boolean;
  onClose: () => void;
}

export const ExportTOCDocModal = React.memo((props: ExportTOCDocModalProps) => {
  const { tr } = useLang();
  const [data, setData] = React.useState({
    name: "Table of Content",
    type: ApiV2.ExportTocType.NUMBER_0 as ApiV2.ExportTocType,
  });
  React.useEffect(() => {
    const userConfigData = LocalStorageKey.GetOjectItem<ApiV2.UserConfigApp>(
      LocalStorageKey.UserConfigApp
    );
    if (userConfigData) {
      setData({
        ...data,
        type:
          userConfigData.tocSettings?.exportType ||
          ApiV2.ExportTocType.NUMBER_0,
      });
    }
  }, [props.isShow]);
  const onSubmit = async () => {
    const userConfigData = LocalStorageKey.GetOjectItem<ApiV2.UserConfigApp>(
      LocalStorageKey.UserConfigApp
    );
    const name =
      (data.name || "Table of Content") +
      "." +
      (data.type === ApiV2.ExportTocType.NUMBER_0 ? ".docx" : "pdf");
    if (userConfigData) {
      userConfigData.tocSettings = {
        ...userConfigData.tocSettings,
        exportType: data.type,
      };
      LocalStorageKey.SetItem(userConfigData, LocalStorageKey.UserConfigApp);
    }
    const response = (
      await TocApi.apiLegalxtractExportTOCDocGet(data.type, {
        responseType: "blob",
      })
    ).data;
    await saveAs(response, name);
    props.onClose();
  };
  const onCloseModal = () => {
    props.onClose();
  };
  const fileTypes = [
    { key: ApiV2.ExportTocType.NUMBER_0, text: tr("Docx") },
    { key: ApiV2.ExportTocType.NUMBER_1, text: tr("PDF") },
  ];
  return (
    <Modal isOpen={props.isShow} onDismiss={onCloseModal} isBlocking={true}>
      <ModalContent style={{ width: 550 }}>
        <div className="ms-Grid" dir="ltr">
          <Stack tokens={verticalStackTokens}>
            <ModalHeader title={tr("fileName")} onCloseModal={onCloseModal} />
            <Dropdown
              label={tr("fileFormat")}
              options={fileTypes}
              selectedKey={data.type}
              onChange={(e, option, index) => {
                setData({
                  ...data,
                  type: (option?.key ||
                    ApiV2.ExportTocType.NUMBER_0) as ApiV2.ExportTocType,
                });
              }}
            />
            <TextField
              label={tr("name")}
              value={data.name || ""}
              autoFocus={true}
              onChange={(e: any) => {
                setData({ ...data, name: e.target.value });
              }}
            />
            <Stack horizontal horizontalAlign="end" tokens={stackTokens}>
              <DefaultButton
                text={tr("cancel")}
                onClick={onCloseModal}
                allowDisabledFocus
              />
              <PrimaryButton
                text={tr("save")}
                onClick={onSubmit}
                allowDisabledFocus
              />
            </Stack>
          </Stack>
        </div>
      </ModalContent>
    </Modal>
  );
});
