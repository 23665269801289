import * as React from "react";
import { useRecoilState } from "recoil";
import * as Api from "../../../ApiV2";
import { StyledTOCContainer } from "../../../assets/style/left-sidebar/searchTab";
import { useLang } from "../../../i18n/useLang";
import {
  TOCState,
  docuviewareMainState,
  modalContainerState,
  trackingState,
} from "../../../recoil";
import { TOCService } from "../../../service";
import TreeToc from "./tree-Toc";
import { documentService } from "../../../service/document";
import { DataCache } from "../../../config/constant";
import ReOrderFromTocCommand from "../../../command/reOrder-command";
import { PrimaryButton } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { ExportTOCDocModal } from "../../../modal/toc/ExportTOCDocModal";
const TocApi = new Api.TocApi();
const TOC = () => {
  const { tr } = useLang();
  const { getTOCInfo } = TOCService();
  const [tocCurrentState, setTOCState] = useRecoilState(TOCState);
  const [docuviewareMain, setDocuviewareMain] =
    useRecoilState(docuviewareMainState);
  const [modalContainer, setModalContainerState] =
    useRecoilState(modalContainerState);
  const [tracking, setTrackingState] = useRecoilState(trackingState);
  React.useEffect(() => {
    if ((docuviewareMain?.DocuviewareInit?.tabDetails || []).length > 0) {
      getTOCInfo();
    }
  }, []);
  const [IsShowModal, { toggle: SetIsShowModal }] = useBoolean(false);
  const GetAllId = (item: string, treeData: any): string[] => {
    let allIds: string[] = [];
    (treeData[item].children as string[]).forEach((element) => {
      allIds.push(element);
      allIds = [...allIds, ...GetAllId(element.toString(), treeData)];
    });
    return allIds;
  };
  const getOrderIDs = (treeData: any) => {
    const arrId = GetAllId("root", treeData);
    return arrId;
  };
  const reOrderToc = async (
    items: any[],
    parentId: string | null,
    treeData: any
  ) => {
    const arrId: string[] = getOrderIDs(treeData);
    const oldTabOderIds = tocCurrentState.tocData.map((o) => o.uniqueId || "");
    const dicOldTabIds = {} as any;
    tocCurrentState.tocData.forEach((obj) => {
      const key = obj?.uniqueId || "";
      dicOldTabIds[key] = obj.parentId;
    });

    const dicTabId = items.reduce((acc, cur) => {
      acc[cur.index] = parentId;
      return acc;
    }, {});
    // if (JSON.stringify(oldTabOderIds) === JSON.stringify(arrId)) return;
    ReOrderFromTocCommand(
      dicOldTabIds,
      oldTabOderIds,
      arrId,
      dicTabId,
      (data: Api.ReOrderTOCResponse) => {
        if (!data) return;
        setTOCState((cur) => {
          return { ...cur, tocData: data.tocRows || [] };
        });
        setDocuviewareMain((cur) => {
          return {
            ...cur,
            DocuviewareInit: data.docuviewareInit || null,
          };
        });
        setTrackingState({
          ...tracking,
          openingLoadingState: {
            ...tracking.openingLoadingState,
            isOpen: false,
            label: ``,
          },
        });
      },
      () => {
        setTrackingState((cur) => {
          return {
            ...cur,
            openingLoadingState: {
              ...cur.openingLoadingState,
              isOpen: true,
              label: ``,
            },
          };
        });
      }
    );
  };

  return (
    <>
      <div
        className="header-left-panel"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        {tr("toc")}
        <div>
          <PrimaryButton
            text={tr("edit")}
            onClick={() => {
              setModalContainerState((cur) => {
                return {
                  ...cur,
                  showTOCListModal: true,
                };
              });
            }}
          ></PrimaryButton>
          &nbsp; &nbsp;
          <PrimaryButton
            id="export"
            text={tr("export")}
            onClick={() => {
              SetIsShowModal();
            }}
          ></PrimaryButton>
        </div>
      </div>
      <StyledTOCContainer onContextMenu={(e) => {}}>
        {tocCurrentState.tocData.length > 0 && (
          <TreeToc
            onDrop={(items, target, treeData) => {
              let parentid = (target as any).parentItem;
              if (parentid === "root") {
                parentid = "00000000-0000-0000-0000-000000000000";
              }
              if (target.targetType === "item") {
                parentid = (target as any).targetItem;
              }
              reOrderToc(items, parentid, treeData);
            }}
          ></TreeToc>
        )}
      </StyledTOCContainer>
      {IsShowModal && (
        <ExportTOCDocModal
          isShow={IsShowModal}
          onClose={() => {
            SetIsShowModal();
          }}
        ></ExportTOCDocModal>
      )}
    </>
  );
};

export default TOC;
